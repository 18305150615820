*{
    font-family: 'Roboto';
  }
  
    .productHeadings{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3.3vw;
      font-family: 'Roboto';
      margin-top: 6vw;
      margin-bottom: 4vw;
    }
  
  .spread{
    flex-direction: column;
  }
  
  @media only screen and (max-width: 600px)
  {    
      .productHeadings{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 7.9vw;
        margin-top: 21vw;
        font-weight: 900;
        margin-bottom: 5vw;
      }
  }
  
  @media only screen and (min-width:601px) and (max-width: 900px)
  { 
      .spread{
        margin-right: -3vw;
      }
    
    
      .productHeadings{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 7.3vw;
        margin-top: 15vw;
        margin-bottom: 6vw;
        font-weight: 800;
      }
  }
  
  