*{
    font-family: 'Roboto';
}

.navbar{
    background-color: black;
    color: white;
    display: flex;
    width: 100%;
}
.container{
    display: flex;
    margin-left: 0.5vw;
    margin-right:0vw;

}
.brandName{
    font-size: 1.5vw;
    color: white;
    font-weight:600;
}
.paths{
    display: flex;
    color: white;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 41vw;
    margin-left: 42vw;
    color: white;
}
.path1{
    color: white;
    text-decoration: none;
    font-weight: 600;
    margin-top: 0.6vw
}
.path2{
    color: white;
    text-decoration: none;
    font-weight: 600;
    margin-top: 0.6vw
}
.category{
    color: white;
    font-weight: 600;
}
.linkksss{
    font-size: 1.1vw;
}
.ico{
    display: block;
    margin-top: 1.8vw;
    cursor: pointer;
    margin-left: 2vw;
    margin-right: 2vw;
}

.badg{
    width: 2.7vw;
    font-size: 1vw;
    height: 1.5vw;
    margin-top: 1vw;
}

@media only screen and (max-width: 600px)
{
    .navbar{
        background-color: transparent;
        color: white;
        display: flex;
        max-width: 10%;
        height: 10vw;
    }.hamburge{
        background-color: lightcoral;
        font-size: 4vw;
        margin-top: 2vw;
        position:sticky;
    }
    .brandName{
        display: none;
    }
    .paths{
        display: flex;
        color: white;
        max-width: auto;
        min-width: 60vw;
        height: auto;
        background-color: black;
        margin-left: 2vw;
        color: white;
    }
    .path1{
        color: white;
        display: flex;
        justify-content: center;
        text-decoration: none;
        font-weight: 600;
        margin-top: 6vw;
    }
    .path2{
        color: white;
        display: flex;
        justify-content: center;
        text-decoration: none;
        font-weight: 600;
        margin-top: 6vw;
        margin-bottom:1vw;
    }
    .category{
        color: white;
        display: block;
        margin: auto;
        text-decoration: none;
        font-weight: 600;
        margin-top: 6vw;
    }
    .ico{
        margin-top: 9vw;
        cursor: pointer;
        display: block;
        margin-left: 25vw;

    }

}


@media only screen and (min-width:601px) and (max-width: 900px)
{
    .navbar{
        background-color: transparent;
        color: white;
        display: flex;
        max-width: 10%;
        height: 10vw;
    }.hamburge{
        background-color: lightcoral;
        font-size: 4vw;
        margin-left: 1vw;
        margin-top: 1vw;
    }
    .brandName{
        display: none;
    }
    .paths{
        display: flex;
        color: white;
        max-width: auto;
        min-width: 60vw;
        height: auto;
        background-color: black;
        margin-left: 2vw;
        color: white;
        font-size: 4vw;
    }
    .path1{
        color: white;
        display: flex;
        justify-content: center;
        text-decoration: none;
        font-weight: 600;
        margin-top: 6vw;
    }
    .path2{
        color: white;
        display: flex;
        justify-content: center;
        text-decoration: none;
        font-weight: 600;
        margin-top: 6vw;
        margin-bottom: 1vw;
    }
    .category{
        color: white;
        display: block;
        margin: auto;
        text-decoration: none;
        font-weight: 600;
        margin-top: 6vw;
    }
    .c1{
        font-size: 3.4vw;
        font-weight: 600;
    }
    .c2{
        font-size: 3.5vw;
        font-weight: 600;
        display: flex;
        justify-content: center;
    }
    .signIn{
        margin-bottom: 3vw;
        font-size: 4vw;
        width: 50%;
        display: block;
        margin-left: 15vw;
        color: darkslategrey;
        margin-top:3vw;
        font-weight: 700;
    }
    .ico{
        margin-top: 9vw;
        cursor: pointer;
        display: block;
        margin-left: 26vw;
        margin-bottom: 2vw;

    }

}