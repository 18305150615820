*{
  font-family: 'Roboto';
}

.cartCards{
    height: auto;
    width: 50vw;
    box-shadow: -9px 8px 18px #473f3f,
                9px -9px 18px #473f3f;
    
    border-radius: 0.5vw;            
    margin-top: 3vw;
  
  }
  .parentDivs{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vw;
    margin-bottom: 5vw;
  
  }
  
  .productLists{
    display: flex;
    flex-direction: row;
    margin-top: 3vw;
    margin-left: 2vw;
  }
.namess{
    width: 30vw;
    font-weight: 700;
  }
.pricess{
    width: 7vw;
    font-weight: 700;
  }
.currentStatuss{
        font-weight: 900;
        width:19vw;
}
.deliveryDatess{
  margin-left: 2vw;
}
.deliveryDatess p{
  margin-left: 2vw;
  font-weight: 800;
}

@media only screen and (max-width: 600px)
{
      .cartCards{
        height: auto;
        width: 90vw;
        box-shadow: -9px 8px 18px #473f3f,
                    9px -9px 18px #473f3f;
        
        border-radius: 0.5vw;   
        margin-top: 13vw;            
      
      }
      .parentDivs{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5vw;
        margin-bottom: 5vw;
      
      }
      
      .productLists{
        display: flex;
        flex-direction: row;
        margin-top: 3vw;
        margin-left: 2vw;
      }
    .namess{
        width: 39vw;
        font-weight: 600;
        margin-left: 1vw;
      }
    .pricess{
        width: 24vw;
        font-weight: 600;
      }
    .currentStatuss{
            font-weight: 900;
            width:19vw;
    }
    .deliveryDatess{
      margin-left: 4vw;
      margin-top: 5vw;
    }
    .deliveryDatess p{
      margin-left: 2vw;
      font-weight: 800;
    }
}


@media only screen and (min-width:601px) and (max-width: 900px)
{
        .cartCards{
          height: auto;
          width: 90vw;
          box-shadow: -9px 8px 18px #473f3f,
                      9px -9px 18px #473f3f;
          
          border-radius: 0.5vw;   
          margin-top: 13vw;    
          padding-right: 3.5vw;        
        
        }
        .parentDivs{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 5vw;
          margin-bottom: 5vw;
        
        }
        
        .productLists{
          display: flex;
          flex-direction: row;
          margin-top: 3vw;
          margin-left: 2vw;
        }
      .namess{
          width: 75vw;
          font-weight: 600;
          font-size: 3.3vw;
          margin-left: 1vw;
        }
      .pricess{
          width: 24vw;
          font-size: 3.5vw;
          font-weight: 700;
        }
      .currentStatuss{
              font-weight: 800;
              font-size: 3.2vw;
              margin-left: -5vw;
              width:19vw;
      }
      .deliveryDatess{
        margin-left: 4vw;
        font-size: 2.9vw;
      }
      .deliveryDatess p{
        margin-left: 2vw;
        font-weight: 800;
      }
      .feedback p{
        font-size: 3vw;
      }
}