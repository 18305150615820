*{
    font-family: 'Roboto';
}
.footer{
    background-color: black;
    color: white;
    height: 100%;
    width: 100%;
}

.foot{
    display: flex;
    flex-direction: row;
}
.infos{
    display: flex;
    flex-direction: column;
}

.portfolio{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
 .Mateen{
    margin-bottom: 3vw;
    font-size:1.8vw;
    font-weight: 500;
}

@media only screen and (max-width: 600px)
{
    *{
        font-family: 'Roboto';
    }
    .footer{
        background-color: black;
        color: white;
        height: 100%;
        width: 100%;
    }
    
    .foot{
        display: flex;
        flex-direction: column;
    }
    .infos{
        display: flex;
        flex-direction: column;
        margin-left: 3vw;
    }
    .link{
        margin-top: 1vw;
        font-size: 4vw;
    }
    .portfolio{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
    }
    .Mateen{
        margin-bottom: 7vw;
        font-size: 5.8vw;
        font-weight: 500;
    }
}

@media only screen and (min-width:601px) and (max-width: 900px)
{
    *{
        font-family: 'Roboto';
    }
    .footer{
        background-color: black;
        color: white;
        height: 100%;
        width: 100%;
    }
    
    .foot{
        display: flex;
        flex-direction: column;
    }
    .infos{
        display: flex;
        flex-direction: column;
        margin-left: 1vw;
        font-size: 3vw;
    }
    .headingssss{
        font-size: 4.7vw;
        margin-left: -2vw;
    } 
     .link{
        margin-top: 1vw;
        font-size: 3.4vw;
    }
    .portfolio{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
    }
     .Mateen{
        margin-bottom: 7vw;
        font-size: 5vw;
        font-weight: 500;
    }
    .head{
        font-size: 5vw;
    } 
}