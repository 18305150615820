*{
  font-family: 'Roboto';
}
.vid{
  height: 36vw;
  width: 55%;
  margin: auto;
  padding: 3vw;
}
.vd{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2vw;
  border: 2px solid lightcoral;
}
.cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.card1 {
    --font-color: black;
    --bg-color: white;
    width: 23vw;
    height: 26vw;
    box-shadow: -9px 9px 18px #5a5a5a,
                9px -9px 18px #ffffff;
    display: flex;
    border-radius: 2vw;
    flex-direction: column;
    transition: .4s;
    cursor: pointer;
    position: relative;
  }
  .card1:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 10px 2px #5a5a5a;
    
  }
  
  .card_img1 {
    width: 70%;
    height: 12vw;
    margin-left: 2.5vw;
    margin-top: 0.2vw;
    scale: 1;
  }
  
  .card__descr-wrapper1 {
    padding: 15px;
    display: grid;
  }
  
  .card__title1 {
    color: black;
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 1.6vw;
  }
  
  .card__descr1 {
    color: black;
    margin-left: 1vw;
  }
  
  /* Card 2  */

.card2 {
    --font-color: black;
    --bg-color: white;
    width: 23vw;
    height: 26vw;
    box-shadow: -9px 9px 18px #5a5a5a,
                9px -9px 18px #ffffff;
    display: flex;
    border-radius: 2vw;
    flex-direction: column;
    transition: .4s;
    cursor: pointer;
    position: relative;
  }
  
  .card2:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 10px 2px #5a5a5a;
    
  }
  
  .card_img2 {
    width: 60%;
    height: 12vw;
    margin-left: 4.4vw;
    margin-top: 0.2vw;
  }
  
  .card__descr-wrapper2 {
    padding-left: 1vw;
    padding-right: 1vw;
    display: grid;
  }
  
  .card__title2 {
    color: black;
    text-align: center;
    font-weight: 600;
    font-size: 1.6vw;
  }
  
  .card__descr2 {
    color: black;
    margin-left: 1vw;
    font-size: 1vw;
    margin-bottom: 8vw;
  }
  

  /* Card 3 */
 
  
.card3 {
  --font-color: black;
  --bg-color: white;
  width: 23vw;
  height: 26vw;
  box-shadow: -9px 9px 18px #5a5a5a,
              9px -9px 18px #ffffff;
  display: flex;
  border-radius: 2vw;
  flex-direction: column;
  transition: .4s;
  cursor: pointer;
  position: relative;
}

.card3:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 10px 2px #5a5a5a;
  
}

.card_img3 {
  width: 70%;
  height: 12vw;
  margin-left: 3vw;
  margin-top: -0.2vw;
  scale: 0.8;
}

.card__descr-wrapper3 {
  padding: 15px;
  display: grid;
}

.card__title3 {
  color: black;
  text-align: center;
  font-weight: 600;
  font-size: 1.6vw;
}

.card__descr3 {
  color: black;
  margin-left: 1vw;
  font-size: 1vw;
    margin-bottom: 8vw;
}


/* category */


.categoryHeading{
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'Roboto';
}

.categories h1{
  width: 23vw;
  margin-left: 0.5vw;
}

.categImage{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.catButtons {
  position:relative;
  bottom: 50%;
  color:white;
  background-color: pink;
  font-weight: 800;
  font-size: 2vw;
  text-decoration: none;
  -webkit-text-stroke: 0.2px black;
  border:1px solid white;
}

.category1 {
  box-sizing: border-box;
  width: 29vw;
  height: 39vw;
  background: rgba(217, 217, 217, 0.58);
  box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: all 0.5s;
}

.category1:hover {
  transform: scale(1.05);
  /* .categImage{
    filter: blur(6px);
  } */
}


/* category2 */

.category2 {
  box-sizing: border-box;
  width: 29vw;
  height: 39vw;
  background: rgba(217, 217, 217, 0.58);
  box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: all 0.5s;
}

.category2:hover {
  transform: scale(1.05);
  /* .categImage{
    filter: blur(6px);
  } */
}


/* category3 */

.category3 {
  box-sizing: border-box;
  width: 29vw;
    height: 39vw;
  background: rgba(217, 217, 217, 0.58);
  box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: all 0.5s;
}

.category3:hover {
  transform: scale(1.05);
  /* .categImage{
    filter: blur(6px);
  } */
}


  /* frames */

  .category4 {
    box-sizing: border-box;
    width: 29vw;
    height: 39vw;
    background: rgba(217, 217, 217, 0.58);
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: all 0.5s;
  }
  
  .category4:hover {
     transform: scale(1.05);
    /* z-index: -100;  */
    /* .categImage{
      filter: blur(6px);
    } */
  }



  .productHeading{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    margin-top: 6vw;
    margin-bottom: 4vw;
  }

.spread{
  flex-direction: column;
}

.outsideSp{
  display: flex;
  flex-direction: row;
}



@media only screen and (max-width: 600px)
{
  *{
    font-family: 'Roboto';
  }
  
  
  .cards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card1 {
      --font-color: black;
      --bg-color: white;
      width: 64vw;
      height: 75vw;
      box-shadow: -9px 9px 18px #5a5a5a,
                  9px -9px 18px #ffffff;
      display: flex;
      border-radius: 2vw;
      flex-direction: column;
      transition: .4s;
      cursor: pointer;
      position: relative;
    }
    
    .card1:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 10px 2px #5a5a5a;
      
    }
    
    .card_img1 {
      width: 80%;
      height: 35vw;
      margin-left: 2.5vw;
      scale: 1;
    }
    
    .card__descr-wrapper1 {
      padding-left: 3vw;
      padding-right: 3vw;
      padding-bottom: 2vw;
      padding-top: 0;
      display: block;
    }
    
    .vid{
      height: 53vw;
      width: 80%;
      margin: auto;
      padding: 3vw;
    }
    .vd{
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 5vw;
      border: 2.5px solid lightcoral;
    }
    .card__title1 {
      color: black;
      text-align: center;
      font-weight: 600;
      font-size: 5.5vw;
    }
    
    .card__descr1 {
      color: black;
      font-size: 4.1vw;
      
    }
    
    /* Card 2  */
  
  .card2 {
    --font-color: black;
    --bg-color: white;
    width: 64vw;
      height: 75vw;
    box-shadow: -9px 9px 18px #5a5a5a,
                9px -9px 18px #ffffff;
    display: flex;
    border-radius: 2vw;
    flex-direction: column;
    transition: .4s;
    cursor: pointer;
    position: relative;
    }
    
    .card2:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 10px 2px #5a5a5a;
      
    }
    
    .card_img2 {
      width: 65%;
      height: 32vw;
      margin-left: 10.5vw;
    }
    
    .card__descr-wrapper2 {
      padding-left: 3vw;
      padding-right: 3vw;
      padding-bottom: 3vw;
      padding-top: 0;
      display: block;
    }
    
    .card__title2 {
      color: black;
      text-align: center;
      font-weight: 600;
      font-size: 5vw;
      margin-top: -3vw;
    }
    
    .card__descr2 {
      color: black;
      margin-top: -2vw;
      font-size: 3.3vw;
      padding-top: 0;
    }
    
  
    /* Card 3 */
   
    
  .card3 {
    --font-color: black;
    --bg-color: white;
    width: 64vw;
      height: 75vw;
    box-shadow: -9px 9px 18px #5a5a5a,
                9px -9px 18px #ffffff;
    display: flex;
    border-radius: 2vw;
    flex-direction: column;
    transition: .4s;
    cursor: pointer;
    position: relative;
  }
  
  .card3:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 10px 2px #5a5a5a;
  }
  
  .card_img3 {
    width: 70%;
    height: 32vw;
    margin-left: 10vw;
  }
  
  .card__descr-wrapper3 {
    padding-left: 3vw;
      padding-right: 3vw;
      padding-bottom: 3vw;
      padding-top: 0;
      display: block;
  }
  
  .card__title3 {
    color: black;
    text-align: center;
    font-weight: 600;
    font-size: 5vw;
    margin-top: -3vw;
  }
  
  .card__descr3 {
    color: black;
    margin-top: -2vw;
    font-size: 3.5vw;
    padding-top: 0;
  }
  
  
  /* category */
  
  
  .categoryHeading{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6.6vw;
    font-weight: 500;
  }
  
  .categories{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vw;
  }
  
  .categories h1{
    width: 100%;
    height: 7vw;
  }
  
  .categImage{
    height: 100%;
    width: 100%;
    object-fit:cover;
  }
  
  .catButtons {
    position:relative;
    bottom: 50%;
    color:white;
    background-color: lightcoral;
    font-weight: 800;
    font-size: 5.2vw;
    text-decoration: none;
  }
  
  .category1 {
    box-sizing: border-box;
    width: 75vw;
    height: 100vw;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: all 0.5s;
  }
  
  .category1:hover {
    transform: scale(1.05);
    z-index: 100;
    /* .categImage{
      filter: blur(6px);
    } */
  }
  
  
  /* category2 */
  
  .category2 {
    box-sizing: border-box;
    width: 75vw;
    height: 100vw;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: all 0.5s;
  }
  
  .category2:hover {
    transform: scale(1.05);
    z-index: 100;
    /* .categImage{
      filter: blur(6px);
    } */
  }
  
  
  /* category3 */
  
  .category3 {
    box-sizing: border-box;
    width: 75vw;
    height: 100vw;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: all 0.5s;
  }
  
  .category3:hover {
    transform: scale(1.05);
    z-index: 100;
    /* .categImage{
      filter: blur(6px);
    } */
  }
  
  
    /* frames */
  
    .category4 {
      box-sizing: border-box;
      width: 75vw;
      height: 100vw;
      box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
      cursor: pointer;
      transition: all 0.5s;
    }
    
    .category4:hover {
      transform: scale(1.05);
      z-index: 100;
      /* .categImage{
        filter: blur(6px);
      } */
    }
  
    .spread{
      margin-right: -5vw;
    }
  
  
    .productHeading{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 6.6vw;
      margin-top: 21vw;
      font-weight: 500;
      margin-bottom: 5vw;
    }
}

@media only screen and (min-width:601px) and (max-width: 900px)
{
  *{
    font-family: 'Roboto';
  }
  
  
  .cards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card1 {
      --font-color: black;
      --bg-color: white;
      width: 60vw;
      height: 63vw;
      box-shadow: -9px 9px 18px #5a5a5a,
                  9px -9px 18px #ffffff;
      display: flex;
      border-radius: 2vw;
      flex-direction: column;
      transition: .4s;
      cursor: pointer;
      position: relative;
    }
    
    .card1:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 10px 2px #5a5a5a;
      
    }
   
    .spread{
      margin-right: -3vw;
    }
  
    .card_img1 {
      width: 80%;
      height: 29vw;
      margin-left: 2.5vw;
      scale: 1;
    }
    
    .card__descr-wrapper1 {
      padding-left: 3vw;
      padding-right: 3vw;
      padding-bottom: 2vw;
      padding-top: 0;
      display: block;
    }
    
    .card__title1 {
      color: black;
      text-align: center;
      font-weight: 600;
      font-size: 4.5vw;
    }
    
    .card__descr1 {
      color: black;
      margin-top: 5vw;
      margin-left: 3vw;
      font-size: 4vw;
      padding-top: 0;
    }
    
    /* Card 2  */
  
  .card2 {
    --font-color: black;
    --bg-color: white;
    width: 60vw;
    height: 63vw;
    box-shadow: -9px 9px 18px #5a5a5a,
                9px -9px 18px #ffffff;
    display: flex;
    border-radius: 2vw;
    flex-direction: column;
    transition: .4s;
    cursor: pointer;
    position: relative;
    }
    
.vid{
  height: 45vw;
  width: 70%;
  margin: auto;
  padding: 3vw;
}
.vd{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4vw;
  border: 3.8px solid lightcoral;
}
    .card2:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 10px 2px #5a5a5a;
      
    }
    
    .card_img2 {
      width: 53%;
      height: 23vw;
      margin-left: 13vw;
    }
    
    .card__descr-wrapper2 {
      padding-left: 3vw;
      padding-right: 3vw;
      padding-bottom: 2vw;
      padding-top: 0;
      display: block;
    }
    
    .card__title2 {
      color: black;
      text-align: center;
      font-weight: 600;
      font-size: 4.5vw;
    }
    
    .card__descr2 {
      color: black;
      margin-top: -3vw;
      font-size: 3vw;
      padding-top: 2.6vw;
    }
    
  
    /* Card 3 */
   
    
  .card3 {
    --font-color: black;
    --bg-color: white;
    width: 60vw;
    height: 63vw;
    box-shadow: -9px 9px 18px #5a5a5a,
                9px -9px 18px #ffffff;
    display: flex;
    border-radius: 2vw;
    flex-direction: column;
    transition: .4s;
    cursor: pointer;
    position: relative;
  }
  
  .card3:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 10px 2px #5a5a5a;
  }
  
  .card_img3 {
    width: 53%;
      height: 23vw;
      margin-left: 13vw;
  }
  
  .card__descr-wrapper3 {
    padding-left: 3vw;
      padding-right: 3vw;
      padding-bottom: 2vw;
      padding-top: 0;
      display: block;
  }
  
  .card__title3 {
    color: black;
      text-align: center;
      font-weight: 600;
      font-size: 4.5vw;
  }
  
  .card__descr3 {
    color: black;
      margin-top: -3vw;
      font-size: 3.2vw;
      padding-top: 2.6vw;
  }
  
  
  /* category */
  
  
  .categoryHeading{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6vw;
    font-weight: 500;
  }
  
  .categories{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vw;
  }
  
  .categories h1{
    width: 100%;
    height: 7vw;
  }
  
  .categImage{
    height: 100%;
    width: 100%;
    object-fit:cover;
  }
  
  .catButtons {
    position:relative;
    bottom: 50%;
    color:white;
    background-color: lightcoral;
    font-weight: 800;
    font-size: 5.2vw;
    text-decoration: none;
  }
  
  .category1 {
    box-sizing: border-box;
    width: 75vw;
    height: 100vw;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: all 0.5s;
  }
  
  .category1:hover {
    transform: scale(1.05);
    z-index: 100;
    /* .categImage{
      filter: blur(6px);
    } */
  }
  
  
  /* category2 */
  
  .category2 {
    box-sizing: border-box;
    width: 75vw;
    height: 100vw;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: all 0.5s;
  }
  
  .category2:hover {
    transform: scale(1.05);
    z-index: 100;
    /* .categImage{
      filter: blur(6px);
    } */
  }
  
  
  /* category3 */
  
  .category3 {
    box-sizing: border-box;
    width: 75vw;
    height: 100vw;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: all 0.5s;
  }
  
  .category3:hover {
    transform: scale(1.05);
    z-index: 100;
    /* .categImage{
      filter: blur(6px);
    } */
  }
  
  
    /* frames */
  
    .category4 {
      box-sizing: border-box;
      width: 75vw;
      height: 100vw;
      box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
      cursor: pointer;
      transition: all 0.5s;
    }
    
    .category4:hover {
      transform: scale(1.05);
      z-index: 100;
      /* .categImage{
        filter: blur(6px);
      } */
    }
  
    .productHeading{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size:6vw;
      margin-top: 15vw;
      margin-bottom: 6vw;
      font-weight: 500;
    }
}

