*{
    font-family: 'Roboto';
  }
 
.abot{
    margin-top: 6vw;
    display: flex;
    flex-direction: column;
    gap: 2vw;
    justify-content: center;
    align-items: center;
    padding: 5vw;
    padding-left: 12vw;
    padding-right: 12vw;
}
.wa{
    height: 5vw;
    width: 22vw;
}
.abot h1{
    font-weight: 700;
}
.abot p{
    display: flex;
    padding-left: 8vw;
    padding-right: 8vw;
}


@media only screen and (max-width:600px)
{
    .wa{
        height: 12vw;
        width: 45vw;
        margin-bottom: 4vw;
    }
    .abot{
        margin-top: 6vw;
        display: flex;
        flex-direction: column;
        gap: 2vw;
        justify-content: center;
        align-items: center;
        padding: 5vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    .abot h1{
        font-weight: 700;
    }
    .abot p{
        display: flex;
        padding-left: 8vw;
        padding-right: 8vw;
        
    }
    
}


@media only screen and (min-width:601px) and (max-width: 900px)
{
    .wa{
        height: 9.5vw;
        width: 36vw;
        margin-bottom: 2vw;
    }
    .abot{
        margin-top: 6vw;
        display: flex;
        flex-direction: column;
        gap: 2vw;
        justify-content: center;
        align-items: center;
        padding: 5vw;
        padding-left: 12vw;
        padding-right: 12vw;
    }
    .abot h1{
        font-weight: 700;
        font-size: 6vw;
    }
    .abot p{
        display: flex;
        font-size: 3vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    
}