*{
  font-family: 'Roboto';
}

.form_mainl {
  margin-top: 6vw;
  width: 27vw;
  height: 32vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  padding: 30px 30px 30px 30px;
  border-radius: 30px;
  box-shadow: 4px 4px 40px rgb(46, 41, 41);
}
.OutestDivl{
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.headingl {
  font-size: 2.5em;
  color: #2e2e2e;
  font-weight: 700;
  margin-top: -1vw;
}
  
  .inputContainerl {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .inputFieldl {
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgb(173, 173, 173);
    border-radius: 30px;
    margin: 10px 0;
    color: black;
    font-size: .8em;
    font-weight: 500;
    box-sizing: border-box;
    padding-left: 30px;
  }
  
  .inputFieldl:focus {
    outline: none;
    border-bottom: 2px solid rgb(199, 114, 255);
  }
  
  .inputFieldl::placeholder {
    color: rgb(80, 80, 80);
    font-size: 1em;
    font-weight: 500;
  }
  
  #buttonl {
    position: relative;
    width: 100%;
    border: 2px solid lightcoral;
    background-color: lightcoral;
    height: 40px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 2vw;
    margin: 10px;
    margin-top: 2vw;
    cursor: pointer;
    overflow: hidden;
    color: white;
    font-size: 1.3vw;
    font-weight:600
  }
  
   #buttonl::after {
    content: "";
    position: absolute;
    background-color: rgba(255, 255, 255, 0.253); 
    height: 100%;
    width: 150px;
    top: 0;
    left: -200px;
    border-bottom-right-radius: 100px;
    border-top-left-radius: 100px;
    filter: blur(10px);
    transition-duration: .5s;
  } 
  
  #buttonl:hover::after {
    transform: translateX(600px);
    transition-duration: .5s;
  }
  
  .signupContainerl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.3vw;
  }
  
  .signupContainerl p {
    font-size: 1.2vw;
    font-weight: 500;
    color: black;
  }
  
  #btn{
    font-size: 1.3vw;
    font-weight: 600;
    width: 11vw;
    border-radius: 2vw;
    background-color: lightcoral;
    border: 2px solid lightcoral;
    color: white;
  }

  
.alrt{
  margin-bottom: -4vw;
}  

  
@media only screen and (max-width: 600px)
{
  .form_mainl{
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 103vw;
    width: 75vw;
    margin-top: 15vh;
  }
  .alrt{
    margin-top: 15vw;
    margin-bottom: -12vw;
    z-index: 200;
  }
  .OutestDivl{
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  
  .headingl {
    font-size: 2.5em;
    color: #2e2e2e;
    font-weight: 700;
    margin-top: -1vw;
  }
    
    .inputContainerl {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
    
    .inputFieldl {
      width: 100%;
      height: 40px;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid rgb(173, 173, 173);
      border-radius: 30px;
      margin: 10px 0;
      color: black;
      font-size: .8em;
      font-weight: 500;
      box-sizing: border-box;
      padding-left: 30px;
    }
    
    .inputFieldl:focus {
      outline: none;
      border-bottom: 2px solid rgb(199, 114, 255);
    }
    
    .inputFieldl::placeholder {
      color: rgb(80, 80, 80);
      font-size: 1em;
      font-weight: 500;
    }
    
    #buttonl {
    
      width: 75%;
      height: 10%;
      border: 2px solid lightcoral;
      background-color: lightcoral;
      font-weight: 600;
      letter-spacing: 1px;
      border-radius: 2vw;
      margin-top: 2vw;
      cursor: pointer;
      color: white;
      font-size: 3.5vw;
    }
    
    .signupContainerl {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.3vw;
    }
    
    .signupContainerl p {
      font-size: 3vw;
      font-weight: 500;
      color: black;
    }
    
    #btn{
      font-size: 3.5vw;
      font-weight: 600;
      width: 21vw;
      border-radius: 2vw;
      background-color: lightcoral;
      border: 2px solid lightcoral;
      color: white;
    }
  
  

}

@media only screen and (min-width:601px) and (max-width: 900px)
{
  .form_mainl{
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 82vw;
    width: 60vw;
    margin-top: 15vh;
    padding: 5vw;
  }
  .alrt{
    margin-top: 20vw;
    margin-bottom: -10vw;
    z-index: 200;
  }
  
  .alert p{
    font-size: 2.7vw;
    font-weight: 600;
    /* margin-top: 1vw; */
}

  .OutestDivl{
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  
  .headingl {
    font-size: 9vw;
    color: #2e2e2e;
    font-weight: 700;
    margin-top: -3vw;
    margin-bottom: 5vw;
  }
    
    .inputContainerl {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
    
    .inputFieldl {
      width: 100%;
      height: 60px;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid rgb(173, 173, 173);
      border-radius: 30px;
      margin: 10px 0;
      margin-top: 3vw;
      color: black;
      font-size: 2.7vw;
      font-weight: 500;
      box-sizing: border-box;
      padding-left: 30px;
    }
    
    .inputFieldl:focus {
      outline: none;
      border-bottom: 2px solid rgb(199, 114, 255);
    }
    
    .inputFieldl::placeholder {
      color: rgb(80, 80, 80);
      font-size: 1em;
      font-weight: 500;
    }
    
    #buttonl {
    
      width: 65%;
      height: 10%;
      border: 2px solid lightcoral;
      background-color: lightcoral;
      font-weight: 600;
      letter-spacing: 1px;
      border-radius: 2vw;
      margin-top: 3vw;
      cursor: pointer;
      color: white;
      font-size: 3.5vw;
    }
    
    .signupContainerl {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1vw;
    }
    
    .signupContainerl p {
      margin-top: 3vw;
      font-size: 3vw;
      font-weight: 500;
      color: black;
    }
    
    #btn{
      font-size: 3.5vw;
      font-weight: 600;
      width: 21vw;
      border-radius: 2vw;
      background-color: lightcoral;
      border: 2px solid lightcoral;
      color: white;
    }
}