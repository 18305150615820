*{
  font-family: 'Roboto';
}

.form_main {
    width: 27vw;
    height: 32vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    padding: 30px 30px 30px 30px;
    border-radius: 30px;
    box-shadow: 4px 4px 40px rgb(46, 41, 41);
  }
  .OutestDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6vw;
  }

  .headin {
    font-size: 3.6vw;
    color: #2e2e2e;
    font-weight: 700;
    margin-top: -4vw;
    margin-bottom: 1vw;
  }
  
  .inputContainer {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
 
  
  .inputField {
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgb(173, 173, 173);
    border-radius: 30px;
    margin: 10px 0;
    color: black;
    font-size: .8em;
    font-weight: 500;
    box-sizing: border-box;
    padding-left: 30px;
    margin-top: 2vw;

  }
  
  .inputField:focus {
    outline: none;
    border-bottom: 2px solid rgb(199, 114, 255);
  }
  
  .inputField::placeholder {
    color: rgb(80, 80, 80);
    font-size: 1em;
    font-weight: 500;
  }
  
  #button {
    position: relative;
    width: 100%;
    border: 2px solid lightcoral;
    background-color: lightcoral;
    height: 40px;
    color: white;
    font-size: 1.3vw;
    font-weight:600;
    letter-spacing: 1px;
    border-radius: 30px;
    margin: 10px;
    cursor: pointer;
    overflow: hidden;
    margin-top: 3vw;
    margin-bottom: -3vw;
  }
  
  #button::after {
    content: "";
    position: absolute;
    background-color: rgba(255, 255, 255, 0.253);
    height: 100%;
    width: 150px;
    top: 0;
    left: -200px;
    border-bottom-right-radius: 100px;
    border-top-left-radius: 100px;
    filter: blur(10px);
    transition-duration: .5s;
  }
  
  #button:hover::after {
    transform: translateX(600px);
    transition-duration: .5s;
  }
  

.alrt{
  margin-top: 4.2vw;
  margin-bottom: -5vw;
}  

@media only screen and (max-width: 600px)
{
.form_main{
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 95vw;
  width: 80vw;
  margin-top: 15vh;
}
.alrt{
  margin-top: 8vw;
  margin-bottom: -10vw;
  z-index: 200;
}
.OutestDiv{
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.headin {
  font-size: 2.5em;
  color: #2e2e2e;
  font-weight: 700;
  margin-top: -8vw;
}
  
  .inputContainer {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .inputField {
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgb(173, 173, 173);
    border-radius: 30px;
    margin: 10px 0;
    margin-top: 5vw;
    color: black;
    font-size: .8em;
    font-weight: 500;
    box-sizing: border-box;
    padding-left: 30px;
  }
  
  .inputField:focus {
    outline: none;
    border-bottom: 2px solid rgb(199, 114, 255);
  }
  
  .inputField::placeholder {
    color: rgb(80, 80, 80);
    font-size: 1em;
    font-weight: 500;
  }
  
  #button {
  
    width: 70%;
    height: 13%;
    border: 2px solid lightcoral;
    background-color: lightcoral;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 2vw;
    margin-top: 8vw;
    cursor: pointer;
    color: white;
    font-size: 3.8vw;
  }
}

@media only screen and (min-width:601px) and (max-width: 900px)
{
  .form_main{
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 72vw;
    width: 60vw;
    margin-top: 15vh;
    padding: 5vw;
  }
  .alrt{
    margin-top: 6vw;
    margin-bottom: -10vw;
    z-index: 200;
  }
  .alert p{
    font-size: 2.7vw;
    font-weight: 600;
    /* margin-top: 1vw; */
}
  .OutestDiv{
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  
  .headin {
    font-size: 9vw;
    color: #2e2e2e;
    font-weight: 700;
    margin-top: -9vw;
    margin-bottom: 5vw;
  }
    
    .inputContainer {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
    
    .inputField {
      width: 100%;
      height: 60px;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid rgb(173, 173, 173);
      border-radius: 30px;
      margin: 10px 0;
      margin-top: 3vw;
      color: black;
      font-size: 2.7vw;
      font-weight: 500;
      box-sizing: border-box;
      padding-left: 30px;
    }
    
    .inputField:focus {
      outline: none;
      border-bottom: 2px solid rgb(199, 114, 255);
    }
    
    .inputField::placeholder {
      color: rgb(80, 80, 80);
      font-size: 1em;
      font-weight: 500;
    }
    
    #button {
    
      width: 65%;
      height: 13%;
      border: 2px solid lightcoral;
      background-color: lightcoral;
      font-weight: 600;
      letter-spacing: 1px;
      border-radius: 2vw;
      margin-top: 7vw;
      cursor: pointer;
      color: white;
      font-size: 3.5vw;
    }
    
}