*{
    font-family: 'Roboto';
}

.card {
    width: 19vw;
    height: 29vw;
    background: #f5f5f5;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
   }
   
   .card-img {
    height: 14vw;
    width: 20vw;
    border-radius: .5rem;
    transition: .3s ease;
   }

   .img{
    width: 100%;
    height: 100%;
    object-fit:contain;
   }
   
   
   .card-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #ddd;
   }
   
   /*Text*/
   .text-title {
    font-weight: 900;
    font-size: 1.2em;
    line-height: 1.5;
   }
   .text-title2 {
    font-weight: 900;
    font-size: 1.2em;
    line-height: 1.5;
   }
   
   .text-body {
    font-size: .9em;
    padding-bottom: 10px;
   }
   
   
@media only screen and (max-width: 600px)
{
    *{
        font-family: 'Roboto';
    }
    
    .ratings{
        margin-top: -2vw;
       }
    .card {
        width: 45vw;
        height: 72vw;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
       }
       
       .card-img {
        height: 32vw;
        width: 100%;
        border-radius: .5rem;
        transition: .3s ease;
       }
    
       .img{
        width: 100%;
        height: 100%;
        object-fit: cover;
       }
       
       
       .card-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        border-top: 1px solid #ddd;
       }
       
       /*Text*/
       .text-title {
        font-weight: 700;
        font-size: 3.9vw;
        margin-top: 1vw;
       }
       .text-title2 {
        font-weight: 900;
        font-size: 4.5vw;
       }
       
       .text-body {
        font-size: .9em;
        padding-bottom: 10px;
       }
       .nor{
        font-size: 3.3vw;
        font-weight: 500;
       }
       
} 


@media only screen and (min-width:601px) and (max-width: 900px)
{
    *{
        font-family: 'Roboto';
    }
    
    .card {
        width: 45vw;
        height: 65vw;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
       }
       
       .card-img {
        height: 32vw;
        width: 100%;
        border-radius: .5rem;
        transition: .3s ease;
       }
    
       .img{
        width: 100%;
        height: 100%;
        object-fit: cover;
       }
       
       
       .ratings{
        margin-top: -1vw;
       }
       .card-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        border-top: 1px solid #ddd;
       }
       
       /*Text*/
       .text-title {
        font-weight: 700;
        font-size: 4vw;
        margin-top: 1vw;
       }
       .text-title2 {
        font-weight: 900;
        font-size: 4.5vw;
       }
       
       .text-body {
        font-size: .9em;
        padding-bottom: 10px;
       }
       .ratings span{
        font-size: 3.5vw;
        font-weight: 500;
       }
}