*{
    font-family: 'Roboto';
  }
 
.categHeading{
    margin-top: 7vw;
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 600px)
{
    .spread{
     margin-right: -6vw;
    }
    .categHeading{
        margin-top: 16vw;
        font-size: 8vw;
        margin-bottom: 3vw;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width:601px) and (max-width: 900px)
{
    .spread{
        margin-right: -3vw;
       }
       .categHeading{
        margin-top: 10vw;
        font-size: 6.8vw;
        margin-bottom: 2vw;
        display: flex;
        justify-content: center;
    }   
}