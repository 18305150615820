*{
  font-family: 'Roboto';
}
.chck{
  font-size: 1.6vw;
  border: 1px solid black;
}
.cartCard{
  height: auto;
  width: 50vw;
  box-shadow: -9px 8px 18px #473f3f,
              9px -9px 18px #473f3f;
  
  border-radius: 0.5vw;            


}


.empty{
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightcoral;
  margin-top:10%;
  font-size: 2.6vw;
}
.parentDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vw;
  margin-bottom: 5vw;

}

.productList{
  display: flex;
  flex-direction: row;
  margin-top: 3vw;
  margin-left: 2vw;
}
.names{
  width: 35vw;
  font-weight: 700;
}
.prices{
  width: 10vw;
  font-weight: 700;
}
.deleteIcon{
  cursor: pointer;
}
.totalBill{
  display: flex;
  justify-content: center;
  align-items: center;
}
.totalBill h4{
  font-weight:900;
}
.promo{
  margin-left: 4vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.promo h4{
  font-weight: 700;
}
.modeOfPayment{
  margin-left: 4vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.accounts{
  margin-top: 2vw;
  margin-left: 3vw;
}

.paymentButton{
  display: flex;
  margin: auto;
  width: 14vw;
  text-align: center;
  padding-left: 4.6vw;
  font-weight: 700;
}

.paymentHeading{
  margin-left: 2vw;
  font-weight: 900;
  font-size: 1.5vw;
}


@media only screen and (max-width: 600px)
{ 
  .chck{
  font-size: 4.4vw;
  border: 1px solid black;
}
  .cartCard{
    height: auto;
    max-width: 95vw;
    min-width: 95vw;
    box-shadow: -9px 8px 18px #473f3f,
                9px -9px 18px #473f3f;
    
    border-radius: 0.5vw;    
    margin-top: 13vw;        
  
  
  }
  .parentDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vw;
    margin-bottom: 5vw;
  
  }
  
  .productList{
    display: flex;
    flex-direction: row;
    margin-top: 3vw;
    margin-left: 2vw;
  }
  .names{
    min-width: 45vw;
    max-width: 55vw;
    font-weight: 700;
  }
  .prices{
    width: 10vw;
    margin-left: 14vw;
    font-weight: 700;
  }
  .deleteIcon{
    margin-left: 13vw;
    cursor: pointer;
  }
  
.empty{
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightcoral;
  margin-top:30%;
  font-size: 6.5vw;
  margin-left: 2vw;
  margin-right: 2vw;
}

  .totalBill{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .totalBill h4{
    font-weight:900;
  }
  
.promo{
  margin-left: 4vw;
  margin-top: 10vw;
  margin-bottom: 10vw;
  font-size: 3.7vw;
}
.promo h4{
  font-weight: 700;
  font-size: 5vw;
  margin-bottom: 3vw;
}
.promoBtn{
  font-size: 3.7vw;
}
  .modeOfPayment{
    margin-left: 4vw;
    margin-top: 10vw;
    margin-bottom: 10vw;
    font-size: 3.7vw;
  }
  
  .accounts{
    margin-top: 2vw;
    margin-left: 3vw;
    font-size: 3.8vw;
  }
  .paymentOpt{
    font-size: 4.5vw;
  }
  .paymentButton{
    display: flex;
    margin: auto;
    width: 44vw;
    justify-content: center;
    text-align: center;
    font-weight: 700;
  }
  
  .paymentHeading{
    margin-left: 2vw;
    font-weight: 900;
    font-size: 4vw;
  }
  
  
}

@media only screen and (min-width:601px) and (max-width: 900px)
{
  .chck{
  font-size: 3.6vw;
  border: 1px solid black;
}
  .cartCard{
    height: auto;
    min-width: 80vw;
    max-width: 80vw;
    box-shadow: -9px 8px 18px #473f3f,
                9px -9px 18px #473f3f;
    
    border-radius: 0.5vw;            
    margin-top: 6vw;
  
  }
  .parentDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vw;
    margin-bottom: 5vw;
  
  }
  
  .productList{
    display: flex;
    flex-direction: row;
    margin-top: 3vw;
    margin-left: 2vw;
  } 
  .empty{
    display: flex;
    justify-content: center;
    align-items: center;
    color: lightcoral;
    margin-top:30%;
    font-size: 6vw;
  }
  .names{
    min-width: 55vw;
    max-width: 55vw;
    font-weight: 700;
    font-size: 3vw;
  }
  .prices{
    min-width: 15vw;
    max-width: 15vw;
    font-weight: 700;
    font-size: 3vw;
  }
  .deleteIcon{
    cursor: pointer;
    font-size: 3vw;
  }
  .totalBill{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .totalBill h4{
    font-weight:900;
    font-size: 4.7vw;
  }
  .promo{
    margin-left: 4vw;
    margin-top: 4vw;
    margin-bottom: 8vw;
    font-size: 2.9vw;
  }
  .promo h4{
    font-weight: 700;
    font-size: 4vw;
    margin-bottom: 4vw;
  }
  .promo input{
    font-size: 3.1vw;
  }
  .promoBtn{
    font-size: 2.8vw;
  }
  .modeOfPayment{
    margin-left: 4vw;
    margin-top: 4vw;
    margin-bottom: 8vw;
    font-size: 2.9vw;
  }
  
  .accounts{
    margin-top: 6vw;
    margin-left: 6vw;
    font-size: 3vw;
  }
  
  .paymentButton{
    display: flex;
    margin: auto;
    width: 44vw;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 2.9vw;
  }
  .paymentOpt{
    font-size: 3.5vw;
  }

  .paymentHeading{
    margin-left: 2vw;
    font-weight: 900;
    font-size: 3.4vw;
  }
}