*{
    font-family: 'Roboto';
  }
  
.chk{
    margin-left: 8vw;
    margin-right: 1vw;
    border: 1.4px solid grey;
    font-size: 1.8vw;
    margin-top: -0vw;
}
.gft{
    font-weight: 500;
    font-size: 1.4vw;
}

.sld{
    margin-left: 5vw;
    margin-top: 2vw;
    width: 40vw;
    height: 40vw;
}
.rw{
    display: flex;
    flex-direction: row;
}

.info{
    display: flex;
    flex-direction: column;
    margin-top: 4vw;
    margin-right: 2vw;
    margin-left: 7vw;
}

.frm{
    display: flex;
    flex-direction:row;
}

.files{
    width: 40vw;
    margin-left: 10vw;
}

.desc{
    width:26vw;
    height: 4vw;
}

.number{
    width: 26vw;
    height: 3vw;
}

.inputs{
    display: flex;
    flex-direction: column;
}
.addToCart{
    display: flex;
    flex-direction: column;
}
.crumb{
    margin-top: 5.2vw;
    margin-left: 2vw;
}
.alrt{
    margin-top: 1vw;
}

.dw{
    width: 16vw;
    margin-top: 1vw;
    margin-left: 3vw;
    height: 2.5vw;
}

@media only screen and (max-width: 600px)
{
    *{
        font-family: 'Roboto';
    }
    
    .sld{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 70%;
        height: 70%;
    }
    .rw{
        display: flex;
        flex-direction: column;
    }
    .gft{
        font-weight: 500;
        font-size: 4vw;
    }
    .ig{
        width: 100%;
        height: 100%;
    }
    .nm{
        font-size: 6vw;
        margin-top: 5vw;
        font-weight: 600;
    }
    .hd{
        font-size: 4.7vw;
        font-weight: 700;
    }   
    .info{
        display: flex;
        flex-direction: column;
        margin-top: 4vw;
        margin-right: 2vw;
        margin-left: 7vw;
        font-size: 3.5vw;
    }
    
    .frm{
        display: flex;
        flex-direction:column;
    }
    
    .files{
        width: 70vw;
        margin-left: 15vw;
    }
    .chk{
        font-size: 5.3vw;
    }
    .desc{
        width:69vw;
        height: 12vw;
        margin-left: 5vw;
    }
    
    .number{
        width: 50vw;
        height: 8.8vw;
        margin-left: 5vw;
    }
    .expDate{
        margin-top: 6vw;
    }
    .inputs{
        display: flex;
        flex-direction: column;
    }
    .addToCart{
        display: flex;
        flex-direction: column;
    }
    .crumb{
        margin-top: 14vw;
        margin-left: 6vw;
        font-size: 3.4vw;
    }
    .alrt{
        margin-top: 1vw;
        height: 17vw;
        z-index: -1;
    }
    .desch{
        font-size: 4.4vw;
        margin-left: 3vw;
    }
    .dw{
        width: 52vw;
        margin-top: 1vw;
        margin-left: 3vw;
        height: 7vw;
        font-size: 4vw;
    }
    .heightIssue{
        height: 2vw;
    }
    .bn{
        font-size: 4.5vw;
        width: 70%;
        display: flex;
        margin: auto;
        justify-content: center;
        align-items: center;
    }
    
}

@media only screen and (min-width:601px) and (max-width: 900px)
{
    *{
        font-family: 'Roboto';
    }
    
    .sld{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 60%;
        height: 60%;
    }
    .files{
        font-size: 2.8vw;
    }
    .rw{
        display: flex;
        flex-direction: column;
    }
    .ig{
        width: 100%;
        height: 100%;
    }
    .nm{
        font-size: 5vw;
        margin-top: 3vw;
        font-weight: 600;
    }
    .hd{
        font-size: 4vw;
        font-weight: 700;
    }
    .info{
        display: flex;
        flex-direction: column;
        margin-top: 4vw;
        margin-right: 2vw;
        margin-left: 7vw;
        font-size: 3vw;
    }
    
    .frm{
        display: flex;
        flex-direction:column;
    }
    
    .files{
        width: 70vw;
        margin-left: 15vw;
    }
    .chk{
        font-size: 4.4vw;
    }
    .gft{
        font-weight: 500;
        font-size: 3.2vw;
    }
    .desc{
        width:60vw;
        height: 9vw;
        margin-left: 5vw;
        font-size: 3vw;
    }
    
    .number{
        width: 35vw;
        height: 7vw;
        margin-left: 5vw;
        font-size: 3vw;
    }
    .expDate{
        margin-top: 6vw;
        margin-left: 4vw;
        font-size: 3vw;
    }
    .inputs{
        display: flex;
        flex-direction: column;
    }
    .addToCart{
        display: flex;
        flex-direction: column;
    }
    .crumb{
        margin-top: 14vw;
        margin-left: 6vw;
        font-size: 2.7vw;
    }
    .alrt{
        margin-top: 1vw;
        z-index: -1;
    }
    .alrt p{
        font-size: 2.7vw;
        font-weight: 600;
        /* margin-top: 1vw; */
    }
    .desch{
        font-size: 3.3vw;
        margin-left: 4vw;
    }
   
    .dw{
        width: 38vw;
        margin-top: 1.5vw;
        margin-left: 7vw;
        height: 5vw;
        font-size: 3vw;
    }
    .bn{
        font-size: 3.5vw;
        width: 70%;
        display: flex;
        margin: auto;
        justify-content: center;
        align-items: center;
    }
}