*{
    font-family: 'Roboto';
  }
 
.orderDetails{
    margin-top: 3vw;
    margin-left: 2vw;
}
@media only screen and (max-width: 600px)
{
    .modl2{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vw;
       }
       .modlh2{
        font-size: 4vw;
       }
       .modl-inp2{
        font-size: 3.5vw;
       }
}
@media only screen and (min-width:601px) and (max-width: 900px)
{
    .orderDetails{
    font-size: 4vw;
    }
    .modl2{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 110vw;
       }
       .modlh2{
        font-size: 3vw;
       }
       .modl-inp2{
        font-size: 2.3vw;
       }
}