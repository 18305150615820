*{
    font-family: 'Roboto';
}

.card {
    width: 19vw;
    height: 31vw;
    background: #f5f5f5;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
   }
   
   .card-img {
    height: 17vw;
    width: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;
    transition: .3s ease;
    padding: 0.3vw;
    padding-right: 1.4vw;
   }

   .img{
    width: 100%;
    height: 100%;
    object-fit:fill;
   }
   
   .card-info {
    padding-top: 2vw;
    padding-left: 1vw;
    
   }
   
   
   .card-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #ddd;
   }
   
   /*Text*/
   .text-title {
    font-weight: 700;
    font-size: 1.2em;
    line-height: 1.5;
   }
   .text-title2 {
    font-weight: 900;
    font-size: 1.2em;
    line-height: 1.5;
   }
   
   .text-body {
    font-size: .9em;
    padding-bottom: 10px;
   }
   
   
@media only screen and (max-width: 600px)
{
    *{
        font-family: 'Roboto';
    }
    
    .card {
        width: 45vw;
        height: 76vw;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
       }
       
       .card-img {
        height: 38vw;
        width: 45vw;
        border-radius: .5rem;
        transition: .3s ease;
        padding: 1.6vw;
       }
    
       .img{
        width: 100%;
        height: 100%;
        object-fit: fill;
       }
       
       .card-info {
        padding: 0.3vw;
       }
       
       
       .card-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #ddd;
       }
       
       /*Text*/
       .text-title {
        font-weight: 700;
        font-size: 3.9vw;
        padding: 1vw;
        padding-bottom:0;
       }
       .text-title2 {
        font-weight: 900;
        font-size: 4.5vw;
    }
       
       .text-body {
        font-size: .9em;
       }
       .nor{
        font-size: 3.43vw;
        font-weight: 500;
       }
       
} 


@media only screen and (min-width:601px) and (max-width: 900px)
{
    *{
        font-family: 'Roboto';
    }
    
    .card {
        width: 45vw;
        height: 70vw;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        padding: 1vw;
       }
       
       .card-img {
        height: 38vw;
        width: 44vw;
        border-radius: .5rem;
        transition: .3s ease;
       }
    
       .img{
        width: 100%;
        height: 100%;
        object-fit: fill;
       }
       
       .card-info {
        padding-top: 2vw;
        padding-left: 1vw;
        
       }
       
       
       .card-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        border-top: 1px solid #ddd;
       }
       
       /*Text*/
       .text-title {
        font-weight: 700;
        font-size: 3.5vw;
        margin-top: 1vw;
       }
       .text-title2 {
        font-weight: 900;
        font-size: 3.9vw;
       }
       
       .text-body {
        font-size: .9em;
        padding-bottom: 10px;
       }
       .modl{
        font-size: 4vw;
       }
       .modl-inp{
        font-size: 3vw;
       }
}